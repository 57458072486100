
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { RESOURCE_CONFIG, SERVER_CONFIG, SERVER_CONFIG_MC_FOUR, DB_TYPE } from '@/utils/global';
import { debounce } from '@/utils/utils';
@Component({
    name: 'ChooseDialog',
})
export default class ChooseDialog extends Vue {
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private itemTab: any = {
        isSelect: false,
    };
    private selectedDBTab: any = {
        appName: '',
    };
    private dbTypeArr: any = [];
    private dbType: number = 1; // 1: pg;2: oracle;
    private buyTypeArr: any = [];
    private buyType: number = 1; // 1: 月;2: 年; 3:永久;
    private dbActive: number = 1; // 1：缩略状态；2：展开选择
    private shrinkStr: string = ''; // 缩略时的字符串
    // 数据库的cpu
    private dbCpuSetList: any = [];
    private dbFreeCpu: number = 0;
    private dbCpu: number = 0;
    // 数据库的memory
    private dbMemorySetList: any = [];
    private dbFreeMemory: number = 0;
    private dbMemory: number = 0;
    // 数据库的HardDisk
    private dbHardDiskSetList: any = [];
    private dbFreeHardDisk: number = 0;
    private dbHardDisk: number = 0;
    // 数据库的timeNumber
    private dbTimeNumberSetList: any = [];
    private dbFreeTimeNumber: number = 0;
    private dbTimeNumber: number = 0;
    // 服务器的cpu
    private serverCpuSetList: any = [];
    private serverFreeCpu: number = 0;
    private serverCpu: number = 0;
    // 服务器的memory
    private serverMemorySetList: any = [];
    private serverFreeMemory: number = 0;
    private serverMemory: number = 0;
    // 服务器的HardDisk
    private serverHardDiskSetList: any = [];
    private serverFreeHardDisk: number = 0;
    private serverHardDisk: number = 0;
    // 服务器的timeNumber
    private serverTimeNumberSetList: any = [];
    private serverFreeTimeNumber: number = 0;
    private serverTimeNumber: number = 0;
    // 服务器的授权用户数
    private userNumber: number = 0;
    // 产品默认时间列表
    private appTimeNumberSetList: any = [];
    private appTimeList: any = [];
    // 所有商品价格
    private allProductTab: any = {
        appDiscountMoney: 0, // 产品优惠
        appMoney: 0, // 产品价格
        dbDiscountMoney: 0, // 数据库优惠
        dbMoney: 0, // 数据库价格
        licenseDiscountMoney: 0, // 授权用户数优惠
        licenseMoney: 0, // 授权用户数价格
        serverDiscountMoney: 0, // 服务器优惠
        serverMoney: 0, // 服务器价格
    };
    // dialog 相关数据
    private showDialog: boolean = false; // 弹窗显示与关闭
    private tipsFlag: boolean = false; // 不同显示与关闭
    private num: number = 1;
    // 等到商品详情
    private goodsTab: any = {};
    // 规格
    private skuArr: any = [];
    private skuId: number = -1; // -1：默认规格
    // 优惠信息
    private discountItems: any = [];
    // 买断的信息
    private orderDetailGrantTab: any = {};
    private orderDetailGrantNumber: number = 0;
    // 试用转正时 是否与服务器
    private tryBuyHasServer: boolean = false;
    // 服务器免费配置
    private serverFreeTab: any = {};
    private serverFreeFourTab: any = {};
    // 服务器id 为了试用转正时，有正式服务器后使用，因为有正式服务器需要传serverId
    private serverIdMoreDev: string = '-1';
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: () => {
            return {appName: ''};
        },
    }) private selectedDBData!: any; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private addGoods!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private tryBuy!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        if (value) {
            this.$nextTick( () => {
                if (this.itemTab.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE) {
                    this.skuId = -1;
                    this.skuArr = [];
                    this.goodsTab = Object.assign({}, this.itemTab);
                } else {
                    this.goodsDetail();
                }
            });
        }
        this.showDialog = value;
    }
    @Watch('selectedDBData') // 监听父组件传过来的值
    private selectedDBDataFun(value: any) {
        this.selectedDBTab = Object.assign({}, value);
    }
    @Watch('itemData') // 监听父组件传过来的值
    private itemDataFun(value: any) {
        this.itemTab = Object.assign({}, value);
    }
    private init() {
        console.log('init');
        this.skuId = -1;
        this.switchSuk(true);
    }
    // 切换规格时候需要重置的信息
    private async switchSuk(firstFlag = false) {
        this.tipsFlag = false;
        this.dbType = 1;
        this.buyType = 1;
        this.dbActive = 1;
        this.itemTab.orderDetailTimeNumber = 1;
        this.orderDetailGrantNumber = 30;
        if (firstFlag) {
            this.getSkuTab();
        }
        this.getDBTab();
        this.getDBTypeTab();
        this.getBuyTab();
        this.getDiscountTab();
        this.relationDBTime();
        this.$nextTick(() => {
            if (this.selectedDBTab && this.selectedDBTab.appName !== '') {
                if (this.selectedDBTab.appName === 'Oracle') {
                    this.dbCpu = this.selectedDBTab.cpu;
                    this.dbMemory = this.selectedDBTab.memory;
                    this.dbHardDisk = this.selectedDBTab.harddisk;
                    this.dbTimeNumber = this.selectedDBTab.orderDetailTimeNumber;
                    this.dbType = 2;
                    this.relationDBTime();
                } else if (this.selectedDBTab.appName === 'PostgreSQL') {
                    this.dbType = 1;
                }
            }
            if (this.goodsTab.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE) {
                this.itemTab.orderDetailTimeNumber = 1;
            } else {
                // 试用转正的时候 如果是应用需要去判断一下 有没有服务器
                if (this.tryBuy && this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
                    this.getCompanyServer().then(() => {
                        this.propSelectApplication();
                    });
                } else {
                    this.propSelectApplication();
                }
            }
        });
    }
    private goodsDetail() {
        const paramData: any = {};
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/goods/${this.itemTab.id}`)
        .then((res: any) => {
            this.goodsTab = res;
            this.goodsTab.goodsPriceType = res.type;
            const setInfo = localStorage.getItem('set_info_api');
            if (setInfo === null || setInfo === '' || setInfo === undefined) {
                this.getSetInfo();
            } else {
                this.init();
            }
        });
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        this.$httpService.getData({}, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            this.init();
        });
    }
    private relationDBTime() {
        // 数据库配置自定义状态时，配置信息就不重置了，时间也不和应用的一致呢
        if (this.dbActive === 2) {
            return;
        }
        const dbTimeNumMax = Math.max.apply(Math, this.dbTimeNumberSetList.map((item: any) => item.number ));
        if (this.selectedDBTab.orderDetailTimeNumber && this.selectedDBTab.orderDetailTimeNumber > 0) {
            this.dbTimeNumber = this.selectedDBTab.orderDetailTimeNumber;
        } else if (this.itemTab.orderDetailTimeNumber > dbTimeNumMax) {
            this.dbTimeNumber = dbTimeNumMax;
        } else {
            this.dbTimeNumber = this.itemTab.orderDetailTimeNumber;
        }
        if (this.selectedDBTab.cpu && this.selectedDBTab.cpu > 0) {
            this.dbCpu = this.selectedDBTab.cpu;
        } else {
            this.dbCpu = this.dbFreeCpu;
        }
        if (this.selectedDBTab.memory && this.selectedDBTab.memory > 0) {
            this.dbMemory = this.selectedDBTab.memory;
        } else {
            this.dbMemory = this.dbFreeMemory;
        }
        if (this.selectedDBTab.harddisk && this.selectedDBTab.harddisk > 0) {
            this.dbHardDisk = this.selectedDBTab.harddisk;
        } else {
            this.dbHardDisk = this.dbFreeHardDisk;
        }
        // 买断的时候，默认 数据库时长也给一年
        if (this.buyType === 3 || this.buyType === 4) {
            this.dbTimeNumber = 12;
        }
        this.serverTimeNumber = this.dbTimeNumber;
        const dbTimeNum = (this.dbTimeNumber >= 12) ? this.dbTimeNumber / 12 + '年' : this.dbTimeNumber % 12 + '个月';
        this.shrinkStr = `${this.dbCpu}vCpu + ${this.dbMemory}G + ${this.dbHardDisk}G + ${dbTimeNum}`;
    }
    // 组装数据库类型
    private getDBTypeTab() {
        // 试用转正只显示PostgreSQL数据库
        if (this.tryBuy) {
            this.dbTypeArr = [];
            this.dbTypeArr.push({
                dbType: 1,
                dbTypeName: 'PostgreSQL',
            });
            if (this.dbTypeArr.length > 0) {
                this.dbTypeArr.sort((a: any, b: any) => a.dbType - b.dbType);
                this.$nextTick (() => {
                    this.dbType = this.dbTypeArr[0].dbType;
                });
            }
            return;
        }
        if (this.goodsTab.goodsPriceType
            && this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
            && this.goodsTab.databaseTypes && this.goodsTab.databaseTypes.length > 0) {
            this.dbTypeArr = [];
            this.goodsTab.databaseTypes.forEach( (item: any) => {
                if (item.type === 1) {
                    this.dbTypeArr.push({
                        dbType: 1,
                        dbTypeName: 'PostgreSQL',
                    });
                } else if (item.type === 2) {
                    this.dbTypeArr.push({
                        dbType: 2,
                        dbTypeName: 'Oracle',
                    });
                }
            });
            if (this.dbTypeArr.length > 0) {
                this.dbTypeArr.sort((a: any, b: any) => a.dbType - b.dbType);
                this.$nextTick (() => {
                    this.dbType = this.dbTypeArr[0].dbType;
                });
            }
        }
    }
    private judgeDBType() {
        if (this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE &&
            (!this.goodsTab.databaseTypes || this.goodsTab.databaseTypes .length === 0)) {
            return false;
        }
        if (this.selectedDBTab.appName === '') {
            return true;
        }
        if (this.selectedDBTab.appName && this.selectedDBTab.appName !== '' && this.dbTypeArr.length > 0) {
            const isHasDBType = this.dbTypeArr
                .some((el: any) => el.dbTypeName.toLowerCase() === this.selectedDBTab.appName.toLowerCase());
            return isHasDBType;
        }
        return false;
    }
    // 组装 购买方式 按月 按年 永久
    private getBuyTab() {
        this.buyTypeArr = [];
        // 永久价格为0的时候，不显示按月 按年
        let priceItems: any = [];
        if (this.skuId === -1) {
            priceItems = [].concat(this.goodsTab.priceItems);
        } else {
            this.goodsTab.skuList.forEach((item: any) => {
                if (item.id === this.skuId) {
                    priceItems = [].concat(item.priceItems);
                }
            });
        }
        if (priceItems && priceItems.length > 0) {
            priceItems.forEach( (item: any) => {
                if (item.type === 2) {
                    this.buyTypeArr.push({
                        buyType: 1,
                        buyTypeName: '按月',
                    });
                } else if (item.type === 3) {
                    this.buyTypeArr.push({
                        buyType: 2,
                        buyTypeName: '按年',
                    });
                } else if (item.type === 1) {
                    this.buyTypeArr.push({
                        buyType: 3,
                        buyTypeName: '买断',
                        minimumLicenses: item.minimumLicenses || 30,
                    });
                } else if (item.type === 4) {
                    this.buyTypeArr.push({
                        buyType: 4,
                        buyTypeName: '买断',
                        minimumLicenses: item.minimumLicenses || 30,
                    });
                }
            });
        }
        if (this.buyTypeArr.length > 0) {
            this.buyTypeArr.sort((a: any, b: any) => a.buyType - b.buyType);
            this.buyType = this.buyTypeArr[0].buyType;
        }
        this.getAppTimeList();
    }
    // 组装 优惠
    private getDiscountTab() {
        this.discountItems = [];
        // 永久价格为0的时候，不显示按月 按年
        if (this.skuId === -1) {
            this.discountItems = [].concat(this.goodsTab.discountItems);
        } else {
            this.goodsTab.skuList.forEach((item: any) => {
                if (item.id === this.skuId) {
                    this.discountItems = [].concat(item.discountItems);
                }
            });
        }
    }
    // 组装时间列表 月 年
    private getAppTimeList() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置产品时间列表
        this.appTimeNumberSetList = res.appTimeNumberSetList;

        this.appTimeList = [];
        if (this.appTimeNumberSetList.length === 0) {
            return;
        }
        this.appTimeNumberSetList.forEach((item: any) => {
            if (this.buyType === 1 && item.number > 0 && item.number < 12) {
                this.appTimeList.push(item);
            }
            if (this.buyType === 2 && item.number >= 12) {
                this.appTimeList.push(item);
            }
        });
        if (this.appTimeList.length > 0) {
            this.itemTab.orderDetailTimeNumber = this.appTimeList[0].number;
        }
        if (this.buyType === 3 || this.buyType === 4) {
            this.itemTab.orderDetailTimeNumber = 999;
            this.orderDetailGrantTab = this.buyTypeArr.find((item: any) => item.buyType === this.buyType);
            this.orderDetailGrantNumber = this.orderDetailGrantTab.minimumLicenses;
        }
    }
    // 组装数据库配置
    private getDBTab() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置数据库的Cpu
        this.dbCpuSetList = [];
        this.dbFreeCpu =
            res.dbFreeSet.cpu > 0 ? res.dbFreeSet.cpu : res.dbCpuSetList[0].number;
        this.dbCpu = this.dbFreeCpu;
        res.dbCpuSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeCpu) {
                this.dbCpuSetList.push(item);
            // }
        });
        // 配置数据库的Memory
        this.dbMemorySetList = [];
        this.dbFreeMemory =
            res.dbFreeSet.memory > 0 ? res.dbFreeSet.memory : res.dbMemorySetList[0].number;
        this.dbMemory = this.dbFreeMemory;
        res.dbMemorySetList.filter((item: any) => {
            // if (item.number >= this.dbFreeMemory) {
                this.dbMemorySetList.push(item);
            // }
        });
        // 配置数据库的HardDisk
        this.dbHardDiskSetList = [];
        this.dbFreeHardDisk =
            res.dbFreeSet.hardDisk > 0 ? res.dbFreeSet.hardDisk : res.dbHardDiskSetList[0].number;
        this.dbHardDisk = this.dbFreeHardDisk;
        res.dbHardDiskSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeHardDisk) {
                this.dbHardDiskSetList.push(item);
            // }
        });
        // 配置数据库的TimeNumber
        this.dbTimeNumberSetList = [];
        this.dbFreeTimeNumber =
            res.dbFreeSet.freeTimeNumber > 0 ? res.dbFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        this.dbTimeNumber = this.dbFreeTimeNumber;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeTimeNumber) {
            this.dbTimeNumberSetList.push(item);
            // }
        });
        // 配置服务器的TimeNumber
        this.serverTimeNumberSetList = [];
        this.serverFreeTimeNumber =
            res.serverFreeSet.freeTimeNumber > 0 ?
                res.serverFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeTimeNumber) {
            this.serverTimeNumberSetList.push(item);
            // }
        });
        // 试用转正使用 start
        // 配置服务器的Cpu
        this.serverCpuSetList = [];
        this.serverFreeCpu =
            res.serverFreeSet.cpu > 0 ? res.serverFreeSet.cpu : res.serverCpuSetList[0].number;
        // this.serverCpu = this.serverFreeCpu;
        res.serverCpuSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeCpu) {
            this.serverCpuSetList.push(item);
            // }
        });
        // 配置服务器的Memory
        this.serverMemorySetList = [];
        this.serverFreeMemory =
            res.serverFreeSet.memory > 0 ? res.serverFreeSet.memory : res.serverMemorySetList[0].number;
        // this.serverMemory = this.serverFreeMemory;
        res.serverMemorySetList.filter((item: any) => {
            // if (item.number >= this.serverFreeMemory) {
            this.serverMemorySetList.push(item);
            // }
        });
        // 配置服务器的HardDisk
        this.serverHardDiskSetList = [];
        this.serverFreeHardDisk =
            res.serverFreeSet.hardDisk > 0 ? res.serverFreeSet.hardDisk : res.serverHardDiskSetList[0].number;
        // this.serverHardDisk = this.serverFreeHardDisk;
        res.serverHardDiskSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeHardDisk) {
            this.serverHardDiskSetList.push(item);
            // }
        });
        // 得到免费服务器的授权用户数
        res.userLimitList.filter((item: any) => {
            if (item.price === 0) {
                this.userNumber = item.number;
            }
        });
        // 获得3.0服务器的免费配置
        this.serverFreeTab = res.serverFreeSet;
        // 获得4.0服务器的免费配置
        this.serverFreeFourTab = res.serverFreeSet4;
    }
    // 给父组件传值
    @Emit('handleGotoRemove')
    private handleGotoRemoveTodo(): any {
        return;
    }
    // 给父组件传值
    @Emit('chooseClose')
    private closeTodo(num: number): any {
        if (num === 3) {
            const goodsListArr = this.assembleGoodsList();
            if (goodsListArr.length > 0) {
                const goodsList = [].concat(goodsListArr);
                return goodsList;
            }
        } else {
            return [];
        }
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    private handleChange(value: number) {
        if (this.itemTab.orderDetailTimeNumber === undefined) {
            this.itemTab.orderDetailTimeNumber = this.itemTab.minimumQuantity;
        }
    }
    private async dbClick(dbClickType: number, dbClickName: string) {
        if (dbClickType !== this.dbType) {
            if (this.selectedDBTab.appName !== '' && dbClickName !== this.selectedDBTab.appName) {
                this.tipsFlag = true;
                return;
            }
            this.dbType = dbClickType;
            if (dbClickType === 2) {
                this.dbActive = 1;
                this.relationDBTime();
            }
            this.propSelectApplication();
        }
    }
    private async buyClick(buyClickType: number) {
        if (buyClickType !== this.buyType) {
            this.buyType = buyClickType;
            this.getAppTimeList();
            this.relationDBTime();
            this.propSelectApplication();
        }
    }
    private selectTimeNumber() {
        this.relationDBTime();
        this.propSelectApplication();
    }
    private getDBPrice() {
        this.propSelectApplication();
    }
    private setDBActive(dbActiveNum: number) {
        if (dbActiveNum !== this.dbActive) {
            this.dbActive = dbActiveNum;
            this.relationDBTime();
            this.propSelectApplication();
        }
    }
    private propSelectApplication() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const goodsListArr = this.assembleGoodsList();
        if (goodsListArr.length === 0) {
            return;
        }
        paramData.goodsList = [].concat(goodsListArr);
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        paramData.userCheck	= false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.allProductTab = {
                appDiscountMoney: res.appDiscountMoney, // 产品优惠
                appMoney: res.appMoney, // 产品价格
                dbDiscountMoney: res.dbDiscountMoney, // 数据库优惠
                dbMoney: res.dbMoney, // 数据库价格
                licenseDiscountMoney: res.licenseDiscountMoney, // 授权用户数优惠
                licenseMoney: res.licenseMoney, // 授权用户数价格
                serverDiscountMoney: res.serverDiscountMoney, // 服务器优惠
                serverMoney: res.serverMoney, // 服务器价格
            };
        });
    }
    get getDiscountPrice() {
        return parseFloat(this.allProductTab.appMoney).toFixed(2);
    }
    get getOriginalPrice() {
        return parseFloat(this.allProductTab.appMoney + this.allProductTab.appDiscountMoney).toFixed(2);
    }
    get getDBDiscountPrice() {
        return parseFloat(this.allProductTab.dbMoney).toFixed(2);
    }
    get getDBOriginalPrice() {
        return parseFloat(this.allProductTab.dbMoney + this.allProductTab.dbDiscountMoney).toFixed(2);
    }
    get getLicenseDiscountPrice() {
        return parseFloat(this.allProductTab.licenseMoney).toFixed(2);
    }
    get getLicenseOriginalPrice() {
        return parseFloat(this.allProductTab.licenseMoney + this.allProductTab.licenseDiscountMoney).toFixed(2);
    }
    get getServerDiscountPrice() {
        return parseFloat(this.allProductTab.serverMoney).toFixed(2);
    }
    get getServerOriginalPrice() {
        return parseFloat(this.allProductTab.serverMoney + this.allProductTab.serverDiscountMoney).toFixed(2);
    }
    private assembleGoodsList(): any {
        const goodsList = [];
        if (this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            const timeNumListMax = Math.max.apply(Math, this.serverTimeNumberSetList.map((item: any) => item.number ));
            let serverTimeNum = 0;
            if (this.itemTab.orderDetailTimeNumber === 999) {
                serverTimeNum = timeNumListMax;
            } else if (this.itemTab.orderDetailTimeNumber > this.serverFreeTimeNumber) {
                serverTimeNum = this.itemTab.orderDetailTimeNumber;
            } else {
                serverTimeNum = this.serverFreeTimeNumber;
            }
            // 把关联安装商品个数传到 父组件中 需要计算服务器资源使用
            let installRelatedGoodsLen = 0;
            if (this.goodsTab && this.goodsTab.installGoodsRelatedList) {
                // 符合数据库的 关联安装商品数量
                const conformDBList = this.goodsTab.installGoodsRelatedList
                    .filter((el: any) => el.dbTypes.includes(this.dbType));
                installRelatedGoodsLen = conformDBList.length;
            }
            // 应用goods模板
            const itemData: any = {
                appName: this.goodsTab.name,
                goodsId: this.goodsTab.id, // 选中商品传给后端的值
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.itemTab.orderDetailTimeNumber, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.APP_GOODSSCENETYPE,
                suiteName: 'mc',
                dbType: this.dbType,
                serverTimeNumber: serverTimeNum,
                mcVersion: this.goodsTab.mcVersion,
                installRelatedLen: installRelatedGoodsLen, // 只是为了父组件中使用和api无关
            };
            if (this.skuId !== -1) {
                itemData.skuId = this.skuId;
            }
            // 试用转正 理论上资源页 选购商品时，需要判断当前商品是不是试用，如果是 需要试用转正
            // 这里如果修改了，选购完 使用orderGoodsSceneType = 9查看是否是 应用 的判断需要一起更改
            // 发现的问题是：没有服务器的时候，试用某个应用，然后再 云商城 和 资源购买页 购买同个商品 可以正常转正，但是续费的时候好像有些问题
            // 如果是：有服务器的话，是没有这个问题
            // 现在方案 是后台处理，应用试用转正 orderGoodsSceneType 传9的话，也可以正常使用续费等操作
            if (this.tryBuy) {
                itemData.orderGoodsSceneType = RESOURCE_CONFIG.APP_GOODSSCENETYPE_TRY;
                // 使用转正的时候，如果是4.0产品，有正式服务器时，需要设置serverId
                if (this.goodsTab.id !== RESOURCE_CONFIG.MOREDEV_GOODSID
                    && this.goodsTab.mcVersion === '4.0'
                    && this.serverIdMoreDev && this.serverIdMoreDev.toString() !== '-1') {
                    itemData.serverId = this.serverIdMoreDev;
                }
            }
            if ((this.buyType === 3 || this.buyType === 4) && this.orderDetailGrantNumber === undefined) {
                this.$message.warning('请输入有效的授权用户数');
                return [];
            }
            goodsList.push(itemData);
            // 应用买断累的时候 需要添加 授权用户数
            if ((this.buyType === 3 || this.buyType === 4)
                && this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
                const grantNumberData: any = {
                    goodsId: RESOURCE_CONFIG.GRANT_GOODSID, // 选中商品传给后端的值
                    appName: '授权用户数',
                    orderDetailNumber: this.orderDetailGrantNumber, // 选中商品数量(累加)
                    orderDetailTimeNumber: 999, // 时长
                    orderGoodsSceneType: RESOURCE_CONFIG.GRANT_GOODSPRICETYPE,
                    isBuyout: true,
                };
                if (this.skuId !== -1) {
                    grantNumberData.skuId = this.skuId;
                }
                goodsList.push(grantNumberData);
            }
            // 试用转正 没有服务器的情况
            if (this.tryBuy && this.tryBuyHasServer) {
                const serverObj = {
                    goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
                    appName: '云服务器',
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: this.serverTimeNumber, // 时长
                    cpu: this.serverCpu,
                    memory: this.serverMemory,
                    harddisk: this.serverHardDisk,
                    orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
                    dbType: this.dbType,
                };
                goodsList.push(serverObj);
                const userNumberObj = {
                    goodsId: RESOURCE_CONFIG.USERNUMBER_GOODSID, // 选中商品传给后端的值
                    appName: '云用户数',
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: this.serverTimeNumber, // 时长
                    orderGoodsSceneType: RESOURCE_CONFIG.USERNUMBER_GOODSSCENETYPE,
                    userNumber: this.userNumber,
                };
                goodsList.push(userNumberObj);
                if (this.goodsTab.id !== RESOURCE_CONFIG.MOREDEV_GOODSID && this.goodsTab.mcVersion === '4.0') {
                    const moreDevGoodsTimeNumber =
                        this.serverTimeNumber > this.serverFreeTimeNumber
                            ? this.serverTimeNumber : this.serverFreeTimeNumber;
                    const moreDevGoods: any = {
                        appName: 'MoreDev 低代码开发平台',
                        goodsId: RESOURCE_CONFIG.MOREDEV_GOODSID, // 选中商品传给后端的值
                        skuId: process.env.VUE_APP_URL === 'https://cloud.morewis.com'
                            ? RESOURCE_CONFIG.MOREDEV_SKUID
                            : undefined, // 开发平台 生产环境中有规格
                        orderDetailNumber: 1, // 选中商品数量(固定不变的)
                        orderDetailTimeNumber: moreDevGoodsTimeNumber, // 时长 跟云服务器时长保持一致
                        orderGoodsSceneType: RESOURCE_CONFIG.APP_GOODSSCENETYPE_TRY,
                        dbType: itemData.dbType, // itemData.dbType
                        mcVersion: '4.0',
                    };
                    goodsList.push(moreDevGoods);
                }
            }
        } else if (this.goodsTab.goodsPriceType === RESOURCE_CONFIG.SAAS_GOODSPRICETYPE) {
            // SaaS goods模板
            const itemData: any = {
                appName: this.goodsTab.name,
                goodsId: this.goodsTab.id, // 选中商品传给后端的值
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.itemTab.orderDetailTimeNumber, // 时长
                orderGoodsSceneType: this.goodsTab.isPurchasedSaas
                    ? RESOURCE_CONFIG.SAAS_GOODSSCENETYPE_RENEW : RESOURCE_CONFIG.SAAS_GOODSSCENETYPE, // 20首次购买，21续费
                dbType: 0,
            };
            if (this.skuId !== -1) {
                itemData.skuId = this.skuId;
            }
            goodsList.push(itemData);
        } else if (this.goodsTab.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE) {
            // 服务 goods模板
            const itemData: any = {
                appName: this.goodsTab.name,
                goodsId: this.goodsTab.id, // 选中商品传给后端的值
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.itemTab.pricingScheme === 1 ? 1
                    : this.itemTab.orderDetailTimeNumber, // 时长 套餐的时候数量为1
                orderGoodsSceneType: RESOURCE_CONFIG.SERVICE_GOODSSCENETYPE,
                goodsPriceType: this.goodsTab.goodsPriceType,
            };
            if (this.skuId !== -1) {
                itemData.skuId = this.skuId;
            }
            goodsList.push(itemData);
        }
        if (this.dbType === 2 && this.goodsTab.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            const dbItemData = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID, // 选中商品传给后端的值
                appName: 'Oracle',
                orderDetailNumber: 1, // 选中商品数量(累加)
                orderDetailTimeNumber: this.dbTimeNumber, // 时长
                cpu: this.dbCpu,
                memory: this.dbMemory,
                harddisk: this.dbHardDisk,
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
            };
            goodsList.push(dbItemData);
        }
        return goodsList;
    }
    private handleTipsClose() {
        this.tipsFlag = false;
    }
    private gotoRemove() {
        console.log('gotoRemove');
        this.handleGotoRemoveTodo();
    }
    private getSkuTab() {
        this.skuArr = [];
        if (this.goodsTab.skuList && this.goodsTab.skuList.length > 0) {
            if (this.goodsTab.priceItems && this.goodsTab.priceItems.length > 0) {
                this.skuArr.push({
                    skuId: -1,
                    skuName: '默认规格',
                });
            }
            this.goodsTab.skuList.forEach( (item: any) => {
                this.skuArr.push({
                    skuId: item.id,
                    skuName: item.name,
                });
            });
            if (this.skuArr.length > 0) {
                this.skuArr.sort((a: any, b: any) => a.skuId - b.skuId);
                this.skuId = this.skuArr[0].skuId;
            }
        }
    }
    private skuClick(skuId: number) {
        if (skuId !== this.skuId) {
            this.skuId = skuId;
            this.switchSuk();
        }
    }
    private handleChangeInputNumber() {
        if (this.orderDetailGrantNumber === undefined) {
            this.orderDetailGrantNumber = this.orderDetailGrantTab.minimumLicenses;
        }
        debounce(() => {
            this.propSelectApplication();
        }, 1000)();
    }
    private getServerPrice() {
        this.propSelectApplication();
    }
    // 获得个人的 服务器列表
    private  getCompanyServer() {
        return new Promise((resolve, reject) => {
            const paramUserId = this.$store.state.userInfo.id;
            this.$httpService.getData({preventRepeat: 1}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
            .then((res: any) => {
                // 3.0正式
                const hasMCThird = res.serverInfoList
                        .some((item: any) => item.serverType  === SERVER_CONFIG.SERVER_TYPE);
                // 4.0正式
                const hasMCFour = res.serverInfoList
                    .some((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE);
                if (this.goodsTab.mcVersion === '3.0' && !hasMCThird) {
                    // 没有3.0正式服务器的时候
                    this.tryBuyHasServer = true;
                    this.serverCpu = this.serverFreeTab.cpu;
                    this.serverMemory = this.serverFreeTab.memory;
                    this.serverHardDisk = this.serverFreeTab.hardDisk;
                    this.serverTimeNumber = this.dbTimeNumber;
                } else if (this.goodsTab.mcVersion === '4.0' && !hasMCFour) {
                    // 没有4.0正式服务器的时候
                    this.tryBuyHasServer = true;
                    // this.serverCpu = this.serverFreeFourTab.cpu;
                    // this.serverMemory = this.serverFreeFourTab.memory;
                    // this.serverHardDisk = this.serverFreeFourTab.hardDisk;
                    this.serverTimeNumber = this.dbTimeNumber;
                    // 把关联安装商品个数传到 父组件中 需要计算服务器资源使用
                    const objMCFourTrial = res.serverInfoList
                            .find((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE_TRIAL);
                    // 这里数量是试用转正时使用，所以计算数量的时候，可以直接看试用服务器应用列表中有多少个应用，直接让这个数量来计算服务器资源就好了
                    // 因为只有试用服务器应用列表中的商品才可以试用转正，不需要跟商品详情的时候计算那么复杂
                    const hasAppFOurLen =
                        objMCFourTrial.userSysTypeInfoList ? objMCFourTrial.userSysTypeInfoList.length : 2;
                    console.log('hasAppFOurLen', hasAppFOurLen);
                    if (hasAppFOurLen <= 2) {
                        this.serverCpu = SERVER_CONFIG_MC_FOUR.FOUR_VCUP;
                        this.serverMemory = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_MEMORY;
                        this.serverHardDisk = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_HARDDISK;
                    } else if (hasAppFOurLen > 2 && hasAppFOurLen <= 4) {
                        this.serverCpu = SERVER_CONFIG_MC_FOUR.SIX_VCUP;
                        this.serverMemory = SERVER_CONFIG_MC_FOUR.SIX_VCUP_MEMORY;
                        this.serverHardDisk = SERVER_CONFIG_MC_FOUR.SIX_VCUP_HARDDISK;
                    } else if (hasAppFOurLen > 4 && hasAppFOurLen <= 6) {
                        this.serverCpu = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP;
                        this.serverMemory = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_MEMORY;
                        this.serverHardDisk = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_HARDDISK;
                    } else {
                        this.serverCpu = SERVER_CONFIG_MC_FOUR.OTHER_VCUP;
                        this.serverMemory = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_MEMORY;
                        this.serverHardDisk = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_HARDDISK;
                    }
                }
                // 有4.0正式服务器的时候
                if (this.goodsTab.mcVersion === '4.0' && hasMCFour) {
                    this.serverIdMoreDev = this.getServerIdMoreDev(res);
                }
                resolve(res);
            });
        });
    }
    // 如果使用转正的时候有4.0正式服务器，拿到第一个服务器的serverId
    private getServerIdMoreDev(res: any) {
        const moreDevServerArr = res.serverInfoList
            .filter((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE);
        moreDevServerArr.sort((a: any, b: any) => a.serverId - b.serverId);
        return moreDevServerArr && moreDevServerArr[0] ? moreDevServerArr[0].serverId : '-1';
    }
}
